<template>
  <div class="qooBox-main">
    <MainHeader v-if="$route.meta.layout == 'default'" />
    <div :class="{ 'router-view-content': $route.meta.layout == 'default' }">
      <router-view />
    </div>
  </div>
  <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue'
import MainHeader from '@/components/base/MainHeader'
import { computed } from 'vue'
import { useStore } from '@/store/loading.js'

const store = useStore()

const isLoading = computed(() => {
  return store.isLoading
})

</script>

<style>
.router-view-content {
  padding-top: 6rem;
}

@media screen and (max-width: 480px) {
  .router-view-content {
    padding-top: 6.5rem;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .router-view-content {
    padding-top: 6.6rem;
  }
}
</style>