<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="bg-white flex flex-col justify-center items-stretch">
    <div
      class="flex-col  box-border overflow-hidden relative flex h-[100vh] w-full justify-start pr-0 pr-md-16 items-start max-md:max-w-full max-md:pr-0">
      <img loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f3b9ed997bb25c3e716c0573a45ebfe3f6c73012227b03e90bbcf327379fec49?apiKey=a03da2e8eec443d0bea06394e57183e2&"
        class="absolute h-full w-full object-cover object-center inset-0" />
      <div
        class="backdrop-blur-[21px] box-border bg-black bg-opacity-70 flex w-[610px] h-[100vh] max-md:overflow-auto hide-scrollbar max-w-full flex-col justify-center items-center px-16 py-8 max-md:px-5">
        <div class="flex w-[372px]  box-border max-w-full flex-col min-h-full max-md:justify-around items-center md:mb-2">
          <img loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd95d20b520b3f3f0ae90d101c8ae601c6f8bba21e465e07492d891e004e3879?apiKey=a03da2e8eec443d0bea06394e57183e2&"
            class="aspect-[2.14] object-contain object-center w-[214px] min-w-[210px] min-h-[100px] md:mt-16 max-md:mb-8 overflow-hidden max-w-full" />
          <div class="text-white text-center text-2xl font-medium w-full mt-32 max-md:mt-10">
            <span class="text-white">Welcome to BeauSoleil.</span><br /><span class="text-base text-white">Please sign in
              to continue</span>
          </div>
          <Form @submit="onSubmit" class="w-full mt-16" ref="formRefLogin" :validation-schema="force ? FormValidationSchemaForce : FormValidationSchema
            ">
            <!-- <div class="w-full mt-16"> -->
            <div class="mt-2 w-full relative">
              <div class="row-top">
                <ErrorMessage name="email"
                  class="error-message text-red-200 bg-transparent top-auto bottom-0 -mb-8 text-sm" />
              </div>
              <Field name="email" id="email" placeholder="Enter your email please" autocomplete="email" type="email"
                class="block w-full form-input rounded-md border-0 text-base py-1.5 text-gray-100 text-start placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
            <div class="bg-white opacity-40 self-stretch shrink-0 h-px mt-1"></div>
            <!-- </div> -->
            <btnAnimation :height="72" class="mt-12 max-md:mt-10" @click="sendMail"></btnAnimation>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import btnAnimation from '@/components/base/common/btnAnimation.vue'
import { onMounted, ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import { login } from '@/services/axios/login.service.js'
import { floors } from '@/services/axios/floors.service.js'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/loading.js'
import { useFloorplan } from '@/store/floor.js'

const store = useStore()
const router = useRouter()
const { cookies } = useCookies()
const formRefLogin = ref(null)

const onSubmit = () => {
  console.log('onSubmit')
}
const FormValidationSchema = yup.object({
  email: yup.string().email()
})

const FormValidationSchemaForce = yup.object({
  email: yup.string().required('Email is a required field').email('Email must be a valid email')
})

const force = ref(false)
onMounted(() => {
  if (process.env.VUE_APP_MANDATORY_LOGIN) force.value = true
})

const sendMail = async () => {
  try {
    if (force.value) {
      const valid = await validation()
      if (valid) {
        const mail = formRefLogin.value.getValues()
        const { data } = await login(mail)
        if (data.token) {
          cookies.set('token', data.token)
          store.accessToken = data.token
          const res = await floors()
          const floorplan = useFloorplan()
          const compare = (a, b) => {
            return a.area - b.area
          }
          res.data.sort(compare)
          floorplan.addFloorplan(res.data)
          router.push({ name: 'floorplans', 'query': { full_3d: true } })
        }
      } else {
        console.log('error');
      }
    } else {
      const data = formRefLogin.value.getValues()
      if (data.email) {
        const valid = await validation()
        if (valid) router.push({ name: 'floorplans' })
      } else {
        router.push({ name: 'floorplans' })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const validation = async () => {
  try {
    if (!formRefLogin.value) {
      throw false
    }
    const v = await formRefLogin.value.validate()
    console.log('valid', v.valid, formRefLogin.value.getValues())
    if (!v.valid) {
      throw false
    }
    return true
  } catch (error) {
    console.error('Error submitting form:', error)
  }
}
</script>
