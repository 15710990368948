<template>
  <div class="main-menu" id="main-sidebar">
    <ul class="flex flex-col md:flex-row max-md:h-[calc(100vh_-_5.5rem)]">
      <li @click="closeSidebar()">
        <router-link :to="{ name: 'floorplans', 'query': { full_3d: true } }" 
        :exact-active-class="getActiveClass(!activeBuilding)">
          <span class="text-[18px] font-medium">Building</span>
        </router-link>
      </li>
      <li @click="closeSidebar()" >
        <router-link :to="{ name: 'floorplans', 'query': { full_3d: false } }" :exact-active-class="getActiveClass(activeBuilding)">
          <span class="text-[18px] font-medium">Floorplans</span>
        </router-link>
      </li>
      <li @click="closeSidebar()">
        <router-link to="/neighbourhood">
          <span class="text-[18px] font-medium">Neighbourhood</span>
        </router-link>
      </li>
      <li @click="closeSidebar()">
        <router-link to="/gallery">
          <span class="text-[18px] font-medium">Gallery</span>
        </router-link>
      </li>
      <li @click="closeSidebar()">
        <router-link to="/builderstory">
          <span class="text-[18px] font-medium">Builder Story</span>
        </router-link>
      </li>
      <li @click="closeSidebar()">
        <router-link to="/broker-package">
          <span class="text-[18px] font-medium">Broker Package</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link to="/contactus" @click="closeSidebar()">
          <span class="text-[18px] font-medium">Contact Us</span>
        </router-link>
      </li> -->
      <li class="mt-3 md:hidden cursor-pointer" @click="favouritesPage">
        <div class="flex items-stretch justify-between rounded-[30px] bg-blue-600 px-9 py-4">
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f2fa2cbf1d2f188673433f303f2e3b37c952554a3cf7df74bc7b0d4d69db748?"
            class="aspect-square w-6 max-w-full shrink-0 overflow-hidden object-contain object-center" />
          <div class="my-auto self-center text-base font-medium mx-4 text-white">Favourites</div>
        </div>
      </li>
      <li v-if="+showWorksheetRoute" class="md:hidden  cursor-pointer" @click="worksheet">
        <div class="flex items-stretch justify-between rounded-[30px] bg-black px-9 py-4">
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/079cc6487a24eac0e01e5360916c17aa76b2ec52aec18266bf8c8b01c8e60b5d?"
            class="aspect-[0.85] w-[17px] max-w-full shrink-0 overflow-hidden object-contain object-center" />
          <div class="my-auto mx-4 grow self-center whitespace-nowrap text-base font-medium text-white">
            Submit Worksheet
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { computed } from 'vue'
const router = useRouter()
const route = useRoute()
const emit = defineEmits(['closeSidebar'])

const showWorksheetRoute = process.env.VUE_APP_SHOW_WORKSHEET;

const closeSidebar = () => {
  try {
    // const main1 = document.getElementsByClassName('main-header')[0]
    // main1.classList.remove('fixed')
    // main1.classList.remove('w-full')
    const line = document.getElementsByClassName('qoo-line')[0]
    line.classList.add('bg-black')

    const sidenav = document.getElementById('main-sidebar')
    const main = document.getElementsByClassName('qooBox-main')[0]
    const menu1 = document.getElementsByClassName('menu-1')[0]

    sidenav.classList.remove('open')
    menu1.classList.remove('open')
    main.classList.remove('open-sidebar')

    document.getElementsByTagName('html')[0].style.overflow = "auto";

    // if (document.body.classList) {
    //   document.body.classList.remove('overflow-hidden');
    //   document.body.classList.remove('h-[100vh]');
    // }
    // if (document.getElementById('app')) {
    //   document.getElementById('app').classList.remove('overflow-hidden');
    //   document.getElementById('app').classList.remove('h-[100vh]');
    // }
    emit('closeSidebar')
  } catch (error) {
    console.log(error)
  }
}

const activeBuilding = computed(() => {
  return route.query.full_3d === 'true';
})

function getActiveClass(activeBuilding) {
  console.log('getActiveClass: ', !activeBuilding ? 'router-link-exact-active' : '');
  return !activeBuilding ? 'router-link-exact-active' : ''
}

const favouritesPage = () => {
  router.push({ name: 'favouritesPage' })
  closeSidebar()
}

const worksheet = () => {
  router.push({ name: 'worksheet' })
  closeSidebar()
}

</script>

<style lang="scss" scoped>
.main-menu {
  // background: #101010 0% 0% no-repeat padding-box;
  // background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  // box-shadow: 4px 0px 16px #00000034;
  width: 100%;
  position: fixed;
  left: 0;
  top: 6rem;
  z-index: 99;
  // padding: 60px 30px 30px 30px;
  height: 100vh;
  overflow-y: auto;
  transform: translateY(-140vh);
  transition: all 0.8s;

  &.open {
    transform: translateY(0px);
  }

  .close-icon {
    display: block;
    text-align: right;
    margin-bottom: 130px;
  }

  .brand {
    text-align: center;

    img {
      width: 250px;
      max-width: 100%;
    }
  }

  ul {
    // background: #ffffff 0% 0% no-repeat padding-box;
    // display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 4rem 1.25rem;

    li {
      margin-bottom: 0px;
      text-align: center;

      a {
        // color: #000;
        font-size: 1.125rem;
        transition: 0.5s ease all;

        span {
          // background-color: #101010;
          position: relative;
          display: block;
          width: max-content;
          text-align: center;
          margin: auto;
          padding: 0 13px;
          z-index: 9;
          opacity: 0.4;
        }

        &.router-link-exact-active,
        &:hover {
          span{
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-menu {
    top: 0;
    height: 100vh;
    ul {
      height: 100% !important;
    }
  }
}
</style>
