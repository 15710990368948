<!-- eslint-disable vue/no-parsing-error -->
<template>
  <button class="w-full inline-block btn-animation">
    <div
      class="bg-mainBlack-1000 self-stretch flex w-full items-stretch justify-between gap-5 py-1.5 pl-1.5 pr-6 rounded-full max-md:pr-5"
      :style="{ height: props.height + 'px' }"
    >
      <button class="learn-more w-full">
        <div class="flex items-stretch justify-between h-full gap-5">
          <div
            class="bg-primary-1000 circle rounded-[100vw] flex justify-center items-center object-contain object-center overflow-hidden shrink-0 max-w-full"
            :style="{
              height: props.height - 12 + 'px',
              width: props.height - 12 + 'px'
            }"
          >
            <div
              class="rounded-full flex justify-center items-center aspect-square object-contain object-center overflow-hidden shrink-0 max-w-full"
              :style="{ height: props.height - 12 + 'px' }"
            >
              <img
                :src="require('@/assets/icons/svg/' + props.icon)"
                :alt="props.text"
              />
            </div>
          </div>
          <div
            class="text-white button-text text-[14px] lg:text-[16px] font-medium my-auto"
          >
            {{ props.text }}
          </div>
        </div>
      </button>
      <div class="self-center flex items-stretch gap-1.5 my-auto">
        <img
          loading="lazy"
          src="@/assets/icons/svg/Vector.svg"
          class="aspect-[0.54] object-contain opacity-30 object-center w-[7px] stroke-[1px] stroke-white overflow-hidden shrink-0 max-w-full"
        />
        <img
          loading="lazy"
          src="@/assets/icons/svg/Vector.svg"
          class="aspect-[0.54] object-contain opacity-50 object-center w-[7px] stroke-[1px] stroke-white overflow-hidden shrink-0 max-w-full"
        />
        <img
          loading="lazy"
          src="@/assets/icons/svg/Vector.svg"
          class="aspect-[0.54] object-contain object-center w-[7px] stroke-[1px] stroke-white overflow-hidden shrink-0 max-w-full"
        />
      </div>
    </div>
  </button>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    default: 'arrow-large.svg'
  },
  text: {
    type: String,
    default: 'Start Exploring'
  },
  routeName: {
    type: String,
    default: 'floorplans'
  },
  height: {
    type: Number,
    default: 60
  }
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Mukta:700');

$bg: #f3f8fa;
$white: #fff;
// $black: #282936;

@mixin transition(
  $property: all,
  $duration: 0.45s,
  $ease: cubic-bezier(0.65, 0, 0.076, 1)
) {
  transition: $property $duration $ease;
}

.btn-animation {
  .self-center {
    img {
      transition: 0.5s ease all;
    }
  }
  &:hover {
    .self-center {
      img {
        opacity: 1 !important;
      }
    }

    button {
      .circle {
        width: 100%;

        .icon {
          &.arrow {
            background: $white;
            transform: translate(1rem, 0);
          }
        }
      }

      .button-text {
        color: $white;
      }
    }
  }
}

button {
  position: relative;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  // background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;

  &.learn-more {
    // width: 14rem;
    height: auto;

    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      display: block;
      margin: 0;
      // width: 60px;
      // height: 60px;
      // background: $black;
      // border-radius: 1.8rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;

        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;

          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }

    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 60px;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    .circle {
      width: 100% !important;

      .icon {
        &.arrow {
          background: $white;
          transform: translate(1rem, 0);
        }
      }
    }

    .button-text {
      color: $white;
    }
  }
}
</style>
